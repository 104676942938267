import { isAuthenticated, getUserData } from 'utils/auth';

export const ROLE_SUPER_ADMIN = 'superadmin';
export const ROLE_RENTSAFE_ADMIN = 'rentsafe_admin';
export const ROLE_PROPERTY_MANAGER = 'property_manager';
export const ROLE_USER = 'user';

export const isSuperAdmin = user => {
  if (isAuthenticated()) {
    const { role } = user || getUserData();
    return role === ROLE_SUPER_ADMIN;
  }
  return false;
};

export const isAdmin = user => {
  if (isAuthenticated()) {
    const { role } = user || getUserData();
    return role === ROLE_RENTSAFE_ADMIN || isSuperAdmin(user);
  }
  return false;
};

export const isPropertyManager = user => {
  if (isAuthenticated()) {
    const { role } = user || getUserData();
    return role === ROLE_PROPERTY_MANAGER || isAdmin(user);
  }
  return false;
};

export const isUser = user => {
  if (isAuthenticated()) {
    const { role } = user || getUserData();
    return role === ROLE_USER || isPropertyManager(user);
  }
  return false;
};

const ROLE_MAP = {
  [ROLE_USER]: isUser,
  [ROLE_PROPERTY_MANAGER]: isPropertyManager,
  [ROLE_RENTSAFE_ADMIN]: isAdmin,
  [ROLE_SUPER_ADMIN]: isSuperAdmin,
};

export const userHasRole = role => {
  try {
    const user = getUserData();
    return ROLE_MAP[role](user);
  } catch (e) {
    return false;
  }
};

export const getRedirectPath = targetRoute => {
  if (isAuthenticated) {
    const { role, needsCompany, isVerified } = getUserData();
    switch (role) {
      case ROLE_USER:
        if (!isVerified) {
          return '/verify_email'
        }

        if (targetRoute && targetRoute.pathname === '/property-manager-dashboard') {
          return '/applicant-dashboard';
        }

        return targetRoute ? targetRoute : '/applicant-dashboard';
      case ROLE_PROPERTY_MANAGER:
        if (!isVerified) {
          return '/verify_email'
        }

        return needsCompany ? '/property-manager-registration' : '/property-manager-dashboard';
      default:
        return targetRoute ? targetRoute : '/property-manager-dashboard';
    }
  }
  return '/';
};
